<template>
  <div class="content-block">
    <div class="content-card-table-condition">
      <v-data-table
        v-model="selectedTable"
        :headers="fields"
        :items="ConditionPaiement"
        class="table-setting"
        hide-default-footer
        :calculate-widths="false"
        :fixed-header="true"
        :items-per-page="perPage"
        hide-action
        :disable-sort="true"
        :no-data-text="
          LoadingCondition
            ? 'Chargement en cours ...'
            : `Il n'y a aucun enregistrement à afficher`
        "
      >
        <!--NAME -->
        <template v-slot:[`item.name`]="{ item }">
          <td class="custom-cell">{{ item.name }}</td>
        </template>
        <!--VALEUR -->
        <template v-slot:[`item.value`]="{ item }">
          <td class="custom-cell">{{ item.value }}</td>
        </template>
        <!--END OF MONTH -->
        <template v-slot:[`item.fin_du_mois`]="{ item }">
          <td class="custom-cell">
            {{ item.fin_du_mois == 1 ? 'Oui' : 'Non' }}
          </td>
        </template>
        <!--ACTIONS-->
        <template v-slot:[`item.Actions`]="{ item }">
          <td class="custom-cell">
            <div class="d-flex actions-style-consition">
              <div
                class="bloc-icon-gestion mr-1"
                v-if="checkPermission('GTRHTMCP')"
                @click.prevent.stop="updateCurrentType(item)"
                title="Modifier condition du paiement"
              >
                <font-awesome-icon icon="pencil-alt" />
              </div>
              <div
                v-if="checkPermission('GTRHTSCP')"
                class="bloc-icon-gestion mr-1"
                @click.prevent.stop="deleteCurrentType(item)"
                title="Supprimer condition du paiement"
              >
                <font-awesome-icon icon="trash" />
              </div>
            </div>
          </td>
        </template>
      </v-data-table>
    </div>
    <!-- FOOTER  -->
    <div class="footer-style-table">
      <div class="text-center pagination-table ">
        <v-pagination
          v-model="page"
          :length="totalPages"
          @input="pagination"
          :total-visible="7"
        ></v-pagination>
      </div>
      <div class="select-input-vuetify mr-2">
        <v-select
          v-model="perPage"
          :items="perPageList"
          @change="changePerPage"
          label="Eléments par page"
          color="#704ad1"
          item-color="#704ad1"
          outlined
          dense
          hide-details
          no-data-text="Aucun élément trouvé"
          :menu-props="{
            left: true,
            offsetY: true,
            closeOnClick: true,
            closeOnContentClick: true
          }"
        ></v-select>
      </div>
    </div>
    <!--MODAL DELETE CONDITION PAIEMENT-->
    <v-dialog
      v-model="deleteConditionPaiementModal"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Supprimer Condition Paiement </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalCondition('delete', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <div class="mt-4 ml-3 text-center">
            <p>
              Êtes-vous sur de vouloir supprimer cet condition du paiement
              <span class="bold-text">
                {{
                  conditionToDelete && conditionToDelete.name
                    ? conditionToDelete.name
                    : ''
                }}
              </span>
              ?
            </p>
          </div>
          <div v-if="errorConditionPaiement" class="message-error-modal">
            <ul v-if="Array.isArray(errorConditionPaiement)" class="mb-0">
              <li v-for="(e, index) in errorConditionPaiement" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>
              {{ errorConditionPaiement }}
            </div>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="handleDeleteCondition"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn text @click="handleModalCondition('delete', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL UPDATE CONDITION PAIEMENT-->
    <v-dialog
      v-model="updateConditionPaiementModal"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card v-if="conditionToUpdate && conditionToUpdate != null">
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"
            >Modifier condition du paiement
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalCondition('update', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <form class="form-add">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nom"
                  v-model="conditionToUpdate.name"
                  :persistent-placeholder="true"
                  :rules="rulesNom"
                  :error-messages="errorMessagesNom"
                  class="msg-error"
                  ref="condition"
                  validate-on-blur
                  color="#704ad1"
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Valeur"
                  v-model="conditionToUpdate.value"
                  :persistent-placeholder="true"
                  :rules="rulesValeur"
                  :error-messages="errorMessagesValue"
                  class="msg-error"
                  ref="value"
                  validate-on-blur
                  color="#704ad1"
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-checkbox
                  class="checked-global"
                  v-model="conditionToUpdate.fin_du_mois"
                  color="#704ad1"
                  :value="1"
                  :unchecked-value="0"
                  label="Fin du mois"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
          </form>
          <div v-if="errorConditionPaiement" class="message-error-modal">
            <ul v-if="Array.isArray(errorConditionPaiement)" class="mb-0">
              <li v-for="(e, index) in errorConditionPaiement" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>
              {{ errorConditionPaiement }}
            </div>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="handleupdateConditionPaiement"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn text @click="handleModalCondition('update', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Gestion-conditions-paiements',
  data() {
    return {
      selectedTable: [],
      rulesNom: [v => !!v || 'Nom condition du paiement est obligatoire.'],
      rulesValeur: [
        v => !!v || 'Valeur condition du paiement est obligatoire.'
      ],
      errorMessagesNom: [],
      errorMessagesValue: [],
      updateConditionPaiementModal: false,
      deleteConditionPaiementModal: false,
      type: null,
      error: null,
      loading: false,
      perPage: 10,
      page: 1,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      fields: [
        {
          value: 'name',
          text: 'Nom du condition',
          width: '30%'
        },
        {
          value: 'value',
          text: 'Valeur',
          width: '30%'
        },
        {
          value: 'fin_du_mois',
          text: 'Fin du mois',
          width: '30%'
        },
        { value: 'Actions', text: 'Actions' }
      ],
      conditionToDelete: null,
      conditionToUpdate: null,
      oldConditionToUpdate: null
    };
  },
  computed: {
    ...mapGetters([
      'ConditionPaiement',
      'errorConditionPaiement',
      'LoadingCondition',
      'checkPermission',
      'rowCondition'
    ]),
    totalPages() {
      if (this.rowCondition) {
        return Math.ceil(this.rowCondition / this.perPage);
      }
      return this.rowCondition;
    }
  },
  methods: {
    ...mapActions([
      'getAllConditionsPaiement',
      'deleteConditionPaiement',
      'updateConditionPaiement',
      'resetMutationsConditions'
    ]),
    handleModalCondition(method, action) {
      if (method == 'update') {
        if (action == 'show') {
          this.updateConditionPaiementModal = true;
        } else {
          this.updateConditionPaiementModal = false;
          this.resetModal();
        }
      }

      if (method == 'delete') {
        if (action == 'show') {
          this.deleteConditionPaiementModal = true;
        } else {
          this.deleteConditionPaiementModal = false;
          this.error = null;
          this.loading = false;
          this.resetMutationsConditions();
        }
      }
    },
    async changePerPage() {
      this.page = 1;
      this.handleFilter();
    },
    async handleFilter() {
      await this.getAllConditionsPaiement({
        per_page: this.perPage,
        page: this.page
      });
    },
    async pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
    },
    resetModal() {
      this.$refs.condition.errorBucket = [];
      this.$refs.value.errorBucket = [];
      this.errorMessagesNom = [];
      this.errorMessagesValue = [];
      this.error = null;
      this.loading = false;
      this.resetMutationsConditions();
    },
    deleteCurrentType(data) {
      this.conditionToDelete = data;
      this.handleModalCondition('delete', 'show');
    },
    async handleDeleteCondition() {
      this.loading = true;
      const response = await this.deleteConditionPaiement(
        this.conditionToDelete
      );
      if (response) {
        this.loading = false;
        this.handleModalCondition('delete', 'hide');
      } else {
        this.loading = false;
      }
    },
    updateCurrentType(data) {
      this.conditionToUpdate = { ...data };
      this.oldConditionToUpdate = data;
      this.handleModalCondition('update', 'show');
    },
    async handleupdateConditionPaiement() {
      if (
        this.$refs.condition.validate() == false ||
        this.$refs.value.validate() == false
      ) {
        this.errorMessagesNom = this.$refs.condition.errorBucket;
        this.errorMessagesValue = this.$refs.value.errorBucket;
        this.loading = false;
      } else {
        // this.conditionToUpdate.fin_du_mois =
        //   this.conditionToUpdate && this.conditionToUpdate.fin_du_mois == true
        //     ? 1
        //     : 0;
        const response = await this.updateConditionPaiement({
          conditionToUpdate: this.conditionToUpdate,
          oldConditionToUpdate: this.oldConditionToUpdate
        });
        if (response) {
          this.loading = false;
          this.handleModalCondition('update', 'hide');
        } else {
          this.loading = false;
        }
      }
    }
  },
  async mounted() {
    this.handleFilter();
  }
};
</script>
<style lang="scss" scoped>
.content-card-table-condition {
  background-color: #fff;
  border-top: 0px;
  padding: 8px 5px 0px 5px;
  height: calc(100vh - 286px);
}
.content-block {
  height: calc(100vh - 250px);
}
.actions-style-consition {
  position: relative;
  margin: auto;
  justify-content: center;
}
</style>
<style lang="scss">
.content-card-table-condition {
  .table-setting .v-data-table__wrapper {
    height: calc(100vh - 293px);
  }
}
</style>
